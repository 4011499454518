@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=STIX+Two+Text:wght@600&display=swap");

@import "../css/variables.scss";

body {
  font-family: "Sarabun", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.heading-font {
  font-family: "STIX Two Text", serif;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
  line-height: 18px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-28 {
  font-size: 28px;
}

.heading-1 {
  font-size: 29px;
  font-weight: 500;
}

.font-bold {
  font-weight: $font-bold;
}

.font-medium {
  font-weight: $font-medium;
}

.font-light {
  font-weight: $font-light;
}

.font-regular {
  font-weight: $font-regular;
}

a {
  text-decoration: none;
  transition: 0.4s;

  &:hover {
    color: $color-secondary !important;
  }
}

.text-brand {
  color: $color-primary;
}

.text-brand-dark {
  color: $color-secondary;
}

.btn-brand-1 {
  background-color: $color-primary;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 42px;
  border: 0;
  padding: 7px 27px;
  line-height: 26px;

  &:hover {
    background-color: $color-secondary;
    color: #fff !important;
  }

  &.btn-sm {
    height: 34px;
    padding: 4px 19px;
    font-weight: 500;
  }
}

.btn-outline-brand {
  @extend .btn-brand-1;
  background-color: transparent;
  border: 1px solid $color-primary;
  color: $color-primary;

  &:hover {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: $color-primary;
  border-color: $color-primary;
}

.container {
  max-width: 1140px;
}

// header
.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  .header-nav {
    .navbar {
      padding: 0;
    }
  }

  .header-nav {
    background-color: $color-secondary;

    .v-devider {
      display: inline-block;
      width: 1px;
      background: #525252;
      height: 20px;
      margin: 0 15px;
    }
  }



  .profile-dd {
    .dropdown-menu {
      min-width: 200px;
      top: 50px;
      border: 0;
      box-shadow: 0px 3px 10px #0000002b;

      .dropdown-item:hover a,
      .dropdown-item:hover .icon {
        color: #000 !important;
      }
    }

    .dropdown-toggle {
      background-color: #00000040;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0;

      &::after {
        border: 0;
      }

      border-radius: 4px;
      padding: 4px 6px;
    }

    .icon {
      width: 18px;
      height: 18px;
      margin-right: -4px;
      color: #ddd;
    }
  }

  .cart-dd {
    @extend .profile-dd;

    .dropdown-toggle {
      padding: 10px 10px;
    }

    .dropdown-menu {
      min-width: 450px;
      padding: 15px;
    }

    .table {
      td {
        padding: 7px 5px;

        a {
          padding: 0;
        }
      }
    }
  }

  .site-nav {
    gap: 15px;

    >li .nav-link {
      color: #fff;

      &:hover {
        color: $color-primary !important;
      }
    }

    .dropend .dropdown-toggle {
      color: $color-primary;
    }

    .dropdown-item:hover {
      background-color: $color-primary;
      color: #fff !important;
    }

    .dropdown .dropdown-menu {
      display: none;
      border-radius: 0;
      padding: 0;
      border: 0;
      box-shadow: 0px 0px 13px #00000012;
      margin-top: 0 !important;

      li {
        .dropdown-item {
          padding: 10px 15px;
          border-bottom: 1px solid #ddd;
          color: #000;

        }

        &:last-child a {
          border: 0;
        }

        .dropdown-menu {
          margin: 0;
        }
      }

      .nav-link:hover {
        color: #fff !important;
      }
    }

    .dropdown:hover>.dropdown-menu,
    .dropend:hover>.dropdown-menu {
      display: block;
      margin-top: 0.125em;
      margin-left: 0.125em;
    }

    @media screen and (min-width: 769px) {
      .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
      }
    }
  }
}

// App Body
.app-body {

  padding-top: 60px;
  min-height: 100vh;
}

.nav.nav-underline {
  border-bottom: 2px solid #ddd;

  .nav-item {
    margin-bottom: -2px;

    .nav-link {
      color: #000;
      min-width: 90px;

      &.active {
        color: $color-primary;
      }
    }
  }
}

.form-control:focus {
  box-shadow: none;
}

.profile-sidebar {
  .nav-link {
    color: $color-secondary;
    margin-bottom: 10px;
    font-weight: 600;

    &:hover {
      background-color: #ffffff45;
      color: #fff !important;
    }

    &.active {
      background-color: $color-secondary;

      &:hover {
        color: #ffffff !important;
      }
    }
  }
}

.prof-left-sibar-col {
  max-width: 295px;
  padding-right: 0;
  background-color: $color-secondary;
  min-height: calc(100vh - 60px);
  margin-top: -1px;

  .profile-sidebar {
    position: sticky;
    top: 80px;
  }

  .nav-pills .nav-link {

    color: #fff;
    border-radius: 0;

    .icon {
      width: 16px;
      margin-right: 10px;
    }

    &.active {
      background-color: #ffffff18;
      color: #fff !important;
    }
  }
}

.form-style {
  .form-label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .form-control {
    height: 41px;
  }
}

.upload-btn-container {
  position: relative;

  img {
    width: 200px;
    height: auto;
  }

  input {
    display: none;
  }

  label {
    width: 100%;
    height: 55px;
    background: #f9f9f9;
    border: 1px dashed #dbdbdb;
    color: #5d5d5d;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &:hover {
      border-color: $color-primary;
    }
  }

  .tb-img-view {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.steps-wizard {
  display: flex;
  text-align: center;
  align-items: center;

  .step-card {
    border-radius: 4px;
    padding: 15px;
    width: 100%;

    a {
      color: $color-secondary;
    }

    &.active {
      border-color: $color-primary;

      a {
        color: $color-primary;
      }
    }

    &:hover a {
      color: $color-primary !important;
    }
  }
}



.table-style {
  border: 1px solid #ddd;

  thead th {
    background-color: $color-secondary-light;
    font-size: 14px;
  }
}

.site-footer {
  background-color: #373d4a;

}

.login-modal {
  z-index: 99999 !important;
}

.loader2 {
  position: absolute;
  left: 44%;
  top: 38vh;
  height: 150px !important;
  width: 150px !important;
}

.delete-modal-box {
  z-index: 99999;
}

.fade.modal-backdrop {
  z-index: 99999;
}

.doc-list-sidebar {
  .accordion-button {
    font-weight: 600;

    a {
      color: #333;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .accordion-body {
    padding: 15px 0;
  }

  .doc-list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      border-bottom: 1px solid #ddd;
      line-height: 22px;

      a {
        color: #000;
        line-height: 18px;
        display: block;
        padding: 12px;
      }

      &:last-child {
        margin-bottom: 0;
        border: 0;
      }
    }
  }
}

.pagination {
  justify-content: end;

  .page-link {
    color: #333;
  }

  .page-item.active {
    .page-link {
      background-color: $color-primary;
      color: #fff;
      border-color: $color-primary;
    }
  }
}

// responsive css
@media (max-width: 767px) {
  .w-xs-100 {
    width: 100%;
  }

  .app-body .container {
    padding: 0;
  }

  .navbar-brand img {
    width: 130px;
    height: 21px;
  }

  .profile-dd {
    img {
      width: 20px;
      height: 20px;
    }
  }

  .cart-dd {
    .dropdown-toggle {
      padding: 4px 7px !important;
      font-size: 15px;
    }

    .dropdown-menu {
      min-width: 375px !important;
    }
  }

  .table-style thead th {
    white-space: nowrap;
  }
}

.button-loader {
  height: 15px;
  margin-right: 5px;
}

.table-style-1 {
  border: 1px solid #ddd;

  tr th {
    font-size: 14px;
    background-color: $color-secondary-light;
  }

  tr td {
    font-size: 15px;
  }

  .action-col {
    text-align: center;
  }

  .action-btns {
    text-align: center;
    display: flex;
    gap: 5px;
    justify-content: center;

    .btn-delete {
      background-color: #fdecec;
      color: #d53727;
      padding: 5px 9px;
      line-height: 13px;

      .icon {
        height: 16px;
      }

      &:hover {
        background-color: #f8d6d6;
      }
    }

    .btn-edit {
      @extend .btn-delete;
      background-color: #ecfdf3;
      color: #027a48;

      &:hover {
        background-color: #caf6dc;
      }
    }


  }
}

.sheet {
  width: 100%;
  overflow-x: scroll;

  .cell-comment-indicator {
    display: none !important;
  }

  .sheet_row {
    .sheet_cell_td {
      div {
        display:block;
        height: 100%;
        width: 100%;

        .no-wordwrap1 {

          // white-space: nowrap !important;
          // text-overflow: ellipsis;
          overflow: visible !important;
        }
      }

      .cell-value {
        padding: 1px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        display: block;
      }
    }
  }
}

@media (max-width: 767px) {
  .excel-file-view{
    overflow: auto;
  }
  .watermark{font-size: 40px !important;}
  .comment-icon + .topics-panel   { 
  top: 230px !important;
  right: 30px !important;
.card-body{
    height: 240px !important;
    overflow: auto;
    font-size: 14px;
  }
}
  .doc-container .topics-panel {
    width: 220px !important;
  }
  .doc-container .topics-panel:not(.comment-icon + .topics-panel) .card-body {
    height: 315px !important;
}
.comment-icon{
  z-index: 70;
}
.sheet{display: block;}

}



.dropdown-menu-scrollable {
  max-height: 70vh; /* 50% of the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Optional: Adjusts the padding to avoid scroll cut-off */
}
