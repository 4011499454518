.login-page {
   background-image: url('../../assets/images/login-bg.jpeg');
   min-height: 100vh;

  .form-control{
      height: 42px;
      font-size: 17px;
  }
  .login-card{
    background-color: #021232c7;
  }

  
}


.reset-error{
  text-align: left;

}


